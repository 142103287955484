import { reportsRoutes } from "~/javascript/router/public/reports";
const PublicLayout = () => import("~/javascript/views/public/PublicLayout.vue");

export const publicRoutes = [
  {
    path: "/public",
    component: PublicLayout,
    children: [
      {
        path: "reports",
        children: reportsRoutes,
      },
    ],
  },
];
