const PublicMediaScheduleAnalysis = () =>
  import("~/javascript/views/public/reports/PublicMediaScheduleAnalysis.vue");

export const reportsRoutes = [
  {
    path: "media_schedules/:token",
    name: "Public Media Schedule Analysis",
    component: PublicMediaScheduleAnalysis,
  },
];
