<script setup>
import { computed } from "vue";
import { useFlashMessageStore } from "~/javascript/stores/flash_messages";

const flashMessageStore = useFlashMessageStore();
const messages = computed(() => Object.values(flashMessageStore.messages));

const clearMessage = (key) => {
  flashMessageStore.clearMessage(key);
};

const alertClasses = (variant) => {
  return `alert alert-${variant} mb-0 alert-dismissible`;
};
</script>

<template>
  <div class="flash-message-wrapper">
    <div v-if="messages.length > 0">
      <ul v-if="messages">
        <li
          v-for="(message, i) in messages"
          :key="`${message}-${i}`"
          :class="alertClasses(message?.variant)"
        >
          {{ message.msg }}
          <button
            class="btn-close"
            @click="clearMessage(message.key)"
            aria-label="Close"
          ></button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
#app-header .flash-message-wrapper ul {
  position: fixed;
  top: 1.25rem;
  z-index: 1000;
}

.flash-message-wrapper ul {
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
}

.flash-message-wrapper ul li {
  list-style: none;
}
</style>
