// utility functions that don't touch state

export const BS_VAR_DANGER = "danger";
export const BS_VAR_INFO = "info";
export const BS_VAR_PRIMARY = "primary";
export const BS_VAR_SECONDARY = "secondary";
export const BS_VAR_SUCCESS = "success";
export const BS_VAR_WARNING = "warning";
export const BS_VAR_PRIMARY_OUTLINE = "outline-primary";

export const LL_BLUE_PRIMARY = "#4B3AEF";
export const LL_BLUE_HIGHLIGHT = "#4EC9F0";
export const LL_PURPLE_MID = "#6F42C1";
export const LL_PINK_LIGHT = "#FFAAD3";
export const LL_PURPLE_LIGHT = "#A7A0FA";
export const LL_WARNING_RED = "#DC3545";

export const CHARTJS_BLUE = "#37a2eb";
export const CHARTJS_RED = "#ff6384";
export const CHARTJS_TEAL = "#4cc0c0";
export const CHARTJS_ORANGE = "#ff9e40";
export const CHARTJS_PURPLE = "#9a66ff";
export const CHARTJS_YELLOW = "#ffcd56";
export const CHARTJS_GREY = "#c9cbcf";

export const CHART_COLORS = [
  CHARTJS_BLUE,
  CHARTJS_RED,
  CHARTJS_TEAL,
  CHARTJS_ORANGE,
  CHARTJS_PURPLE,
  CHARTJS_YELLOW,
  CHARTJS_GREY,
];

export const ALL_COLORS = [
  LL_BLUE_PRIMARY,
  LL_BLUE_HIGHLIGHT,
  LL_PURPLE_MID,
  LL_PINK_LIGHT,
  LL_PURPLE_LIGHT,
  LL_WARNING_RED,
  CHARTJS_BLUE,
  CHARTJS_RED,
  CHARTJS_TEAL,
  CHARTJS_ORANGE,
  CHARTJS_PURPLE,
];

export const kebabCase = (str) => {
  if (!str) return;
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .join("-")
    .toLowerCase();
};

export const debounce = (func, delay, { leading } = {}) => {
  let timerId;
  return (...args) => {
    if (!timerId && leading) func(...args);
    clearTimeout(timerId);
    timerId = setTimeout(() => func(...args), delay);
  };
};

export const capitalize = (str) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const isDesktop = () => {
  return window.innerWidth > 1200;
};

export const zonelessDate = (d) => {
  const date = d.toDateString();
  const time = d.toTimeString().split(" ")[0];
  return `${date} ${time}`;
};

// n is the number of days ago
export const relativeDate = (n) => {
  const today = new Date();
  return new Date(new Date().setDate(today.getDate() - n));
};

export const isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0;
};

export const formatNumber = (num) => {
  if (!num) return "N/A";
  return num.toLocaleString();
};

export const formatCurrency = (num) => {
  if (!num) return "N/A";
  return num.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
};
