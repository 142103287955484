import { useTargetZipStore } from "~/javascript/stores/target_zips";

const TargetZipsCreate = () =>
  import("~/javascript/views/target_zips/TargetZipsCreate.vue");
const TargetZipsIndex = () =>
  import("~/javascript/views/target_zips/TargetZipsIndex.vue");
const TargetZipsShow = () =>
  import("~/javascript/views/target_zips/TargetZipsShow.vue");

const setCurrentTargetZip = (to, from, next) => {
  const store = useTargetZipStore();
  store.loadTargetZip(to.params.target_zip_id);
  next();
};

export const targetZipsRoutes = [
  // TODO: guard these routes depending on module access
  {
    path: "target_zips",
    name: "Target Zips",
    children: [
      {
        path: "",
        component: TargetZipsIndex,
      },
      {
        path: "create",
        name: "New Target Zip",
        component: TargetZipsCreate,
      },
      {
        path: ":target_zip_id",
        name: "Target Zip",
        beforeEnter: setCurrentTargetZip,
        children: [
          {
            path: "",
            name: "Target Zip Home",
            component: TargetZipsShow,
          },
        ],
      },
    ],
  },
];
