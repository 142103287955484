import { defineStore } from "pinia";
import { useContactStore } from "~/javascript/stores/contacts";
import { useMediaScheduleStore } from "~/javascript/stores/media_schedules";
import { useOrgLocationStore } from "~/javascript/stores/org_locations";
import { useOrgStore } from "~/javascript/stores/organizations";
import { useTargetZipStore } from "~/javascript/stores/target_zips";
import { useTargetZoneStore } from "~/javascript/stores/target_zones";
import { useTrackingTagStore } from "~/javascript/stores/tracking_tags";
import { useWebsiteStore } from "~/javascript/stores/websites";
import { useWorkspaceStore } from "~/javascript/stores/workspaces";

export const useApiPathStore = defineStore({
  id: "api_paths",

  getters: {
    // base route
    workspaceRoute() {
      const store = useWorkspaceStore();

      return `/${store.currentWorkspace?.identifier}`;
    },

    // current getters
    currentOrg() {
      return useOrgStore().currentOrg;
    },

    currentOrgLocation() {
      return useOrgLocationStore().currentOrgLocation;
    },

    currentContact() {
      return useContactStore().currentContact;
    },

    currentMediaSchedule() {
      return useMediaScheduleStore().currentMediaSchedule;
    },

    currentTargetZone() {
      return useTargetZoneStore().currentTargetZone;
    },

    currentTargetZip() {
      return useTargetZipStore().currentTargetZip;
    },

    currentTrackingTag() {
      return useTrackingTagStore().currentTrackingTag;
    },

    currentWebsite() {
      return useWebsiteStore().currentWebsite;
    },

    // paths
    contactsPath() {
      return `${this.orgPath()}/contacts`;
    },

    contactPath() {
      return (id) => {
        const idParam = id || this.currentContact?.id;
        return `${this.contactsPath}/${idParam}`;
      };
    },

    contactIndexTablePath() {
      return `${this.contactsPath}/index_table`;
    },

    contactLeadsTablePath() {
      return `${this.contactPath()}/leads_table`;
    },

    contactPurchasesTablePath() {
      return `${this.contactPath()}/purchases_table`;
    },

    orgsPath() {
      return "/organizations";
    },

    orgPath() {
      return (id) => {
        const idParam = id || this.currentOrg?.id;
        return `${this.orgsPath}/${idParam}`;
      };
    },

    orgsIndexTablePath() {
      return `${this.orgsPath}/index_table`;
    },

    orgLocationsPath() {
      return `${this.orgPath()}/org_locations`;
    },

    orgLocationsIndexTablePath() {
      return `${this.orgLocationsPath}/index_table`;
    },

    targetZonesPath() {
      return "/target_zones";
    },

    targetZonesIndexTablePath() {
      return `${this.targetZonesPath}/index_table`;
    },

    targetZonePath() {
      return (id) => {
        const idParam = id || this.currentTargetZone?.id;
        return `${this.targetZonesPath}/${idParam}`;
      };
    },

    targetZoneMediaSchedulesTablePath() {
      return `${this.targetZonePath()}/media_schedules_table`;
    },

    targetZipsPath() {
      return "/target_zips";
    },

    targetZipPath() {
      return (id) => {
        const idParam = id || this.currentTargetZip?.id;
        return `${this.targetZipsPath}/${idParam}`;
      };
    },

    targetZipMediaSchedulesTablePath() {
      return `${this.targetZipPath()}/media_schedules_table`;
    },

    targetZipsIndexTablePath() {
      return `${this.targetZipsPath}/index_table`;
    },

    websitesPath() {
      return `${this.orgPath()}/websites`;
    },

    allWebsitesIndexTablePath() {
      return `/all_websites/index_table`;
    },

    websitesIndexTablePath() {
      return `${this.websitesPath}/index_table`;
    },

    websitePath() {
      return (id) => {
        const idParam = id || this.currentWebsite?.id;
        return `${this.websitesPath}/${idParam}`;
      };
    },

    websiteReportChartPath() {
      return `${this.websitePath()}/website_metrics/chart_data`;
    },

    websiteSourceMediumReportTablePath() {
      return `${this.websitePath()}/website_metrics/source_medium_table`;
    },

    websiteMetricsPath() {
      return `${this.websitePath()}/website_metrics/metric`;
    },

    leadsPath() {
      return `${this.websitePath()}/leads`;
    },

    leadsIndexTablePath() {
      return `${this.leadsPath}/index_table`;
    },

    mediaSchedulesPath() {
      return `${this.websitePath()}/media_schedules`;
    },

    mediaSchedulePath() {
      return (id) => {
        const idParam = id || this.currentMediaSchedule?.id;
        return `${this.mediaSchedulesPath}/${idParam}`;
      };
    },

    mediaSchedulesIndexTablePath() {
      return `${this.mediaSchedulesPath}/index_table`;
    },

    mediaSchedulesTimeZonesPath() {
      return `${this.mediaSchedulesPath}/time_zone_options`;
    },

    mediaScheduleItemsTablePath() {
      return `${this.mediaSchedulePath()}/scheduled_items_table`;
    },

    mediaScheduleAnalysisPath() {
      return `${this.mediaSchedulePath()}/analysis`;
    },

    mediaScheduleAnalyzePath() {
      return `${this.mediaSchedulePath()}/analyze`;
    },

    mediaScheduleResetAnalysisPath() {
      return `${this.mediaSchedulePath()}/reset_analysis`;
    },

    purchasesPath() {
      return `${this.websitePath()}/purchases`;
    },

    purchasesIndexTablePath() {
      return `${this.purchasesPath}/index_table`;
    },

    workspacesPath() {
      return "/workspaces";
    },

    workspaceByIdentifierPath() {
      return (identifier) => {
        return `${this.workspacesPath}/by_identifier/${identifier}`;
      };
    },

    publicMediaScheduleAnalysisPath() {
      return (token) => {
        return `/public/reports/media_schedule_analysis/${token}`;
      };
    },
  },
});
