<script setup>
import { computed } from "vue";

const props = defineProps({
  icon: { type: String, default: null },
  inline: {
    type: Boolean,
    default: false,
  },
});
const iconClass = computed(() => "bi bi-" + props.icon);
</script>

<template>
  <i v-if="props.inline" :class="iconClass" class="icon-inline"></i>
  <div v-else class="form-control-icon">
    <i :class="iconClass"></i>
  </div>
</template>
