<script setup>
import { computed } from "vue";
import ScrollTop from 'primevue/scrolltop';
import { useAuthStore } from "~/javascript/stores/auth";
import LaunchLabsLogo from "~/javascript/components/LaunchLabsLogo.vue";

const props = defineProps({
  isLogin: {
    type: Boolean,
    default: false
  },
  isPublic: {
    type: Boolean,
    default: false
  }
});

const authStore = useAuthStore();
const year = new Date().getFullYear();

const isAdmin = computed(() => {
  if (props.isPublic) return false;
  return !!authStore.getCurrentUser?.global_admin || !!authStore.getCurrentUser?.ll_user;
});

const llUrl = "https://www.launchlabs.ai";
</script>

<template>
  <footer id="app-footer">
    <div class="footer-wrapper">
      <div class="row footer-border">
          <div class="col">
            <hr />
        </div>
      </div>
      <div class="footer text-muted row">
        <p class="col-md-7 col-xs-12 d-flex justify-content-left align-items-end">
          
          <div>
            clickboard
            <template v-if="!isAdmin">&copy;</template>
            <router-link v-if="isAdmin" to="/admin">&copy;</router-link>
            {{ year }}
            <a target="_blank" :href="llUrl">Launch Labs</a>
          </div>
        </p>
        <p class="col-md-5 col-xs-12 d-flex justify-content-end">
          <a target="_blank" :href="llUrl">
            <LaunchLabsLogo />
          </a>
        </p>
        <template v-if="props.isLogin">
          <hr>
          <div class="mt-3 text-center">
            <a href="https://www.launchlabs.ai/privacy-policy" target="_blank">Privacy Policy</a>
          </div>
        </template>
      </div>
    </div>
    <ScrollTop />
  </footer>
</template>

<style>
footer#app-footer {
  margin-top: auto;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

footer .p-scrolltop.p-button {
  bottom: 100px;
}
</style>