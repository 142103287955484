import { defineStore } from "pinia";
import axios from "~/javascript/backend";
import router from "~/javascript/router";
import { useApiPathStore } from "~/javascript/stores/api_paths";
import { usePathStore } from "~/javascript/stores/paths";
import { emitError, emitSuccess } from "~/javascript/helpers";

const DEFAULT_STATE = {
  currentTargetZip: {},
};

export const useTargetZipStore = defineStore({
  id: "target_zips",

  state: () => ({
    ...structuredClone(DEFAULT_STATE),
  }),

  getters: {
    pathStore() {
      const store = usePathStore();
      return store;
    },

    targetZipsPath() {
      return useApiPathStore().targetZipsPath;
    },
  },

  actions: {
    resetState() {
      this.$state = structuredClone(DEFAULT_STATE);
    },

    setCurrentTargetZip(target) {
      this.currentTargetZip = target;
    },

    fetchTargetZips() {
      return axios.get(this.targetZipsPath).then((response) => {
        return response.data;
      });
    },

    async loadTargetZip(id) {
      this.setCurrentTargetZip({});
      return new Promise((resolve) => {
        axios.get(`${this.targetZipsPath}/${id}`).then((response) => {
          this.setCurrentTargetZip(response.data);
          resolve();
        });
      });
    },

    update({ targetZipObj, id }) {
      axios
        .put(`${this.targetZipsPath}/${id}`, targetZipObj)
        .then((response) => {
          this.setCurrentTargetZip(response.data);
          emitSuccess("Target zip updated");
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },

    create(targetZipObj) {
      axios
        .post(this.targetZipsPath, targetZipObj)
        .then((response) => {
          this.setCurrentTargetZip(response.data);
          router.push(`${this.pathStore.targetZipPath(response.data.id)}`);
          emitSuccess("Target zip created");
        })
        .catch((error) => {
          emitError(error);
        });
    },

    delete(id) {
      axios
        .delete(`${this.targetZipsPath}/${id}`)
        .then((response) => {
          router.push(this.pathStore.targetZipsPath);
          emitSuccess(response.data.message);
        })
        .catch((error) => {
          emitError(error.response.data.errors);
        });
    },
  },
});
