const clkbrd = `
░░      ░░░  ░░░░░░░░  ░░░░  ░░       ░░░       ░░░       ░░
▒  ▒▒▒▒  ▒▒  ▒▒▒▒▒▒▒▒  ▒▒▒  ▒▒▒  ▒▒▒▒  ▒▒  ▒▒▒▒  ▒▒  ▒▒▒▒  ▒
▓  ▓▓▓▓▓▓▓▓  ▓▓▓▓▓▓▓▓     ▓▓▓▓▓       ▓▓▓       ▓▓▓  ▓▓▓▓  ▓
█  ████  ██  ████████  ███  ███  ████  ██  ███  ███  ████  █
██      ███        ██  ████  ██       ███  ████  ██       ██
`;
console.log(clkbrd);
console.log("Vue + Vite + Rails =%c emerald", "color:#50c878");

import { createApp } from "vue";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "~/stylesheets/main.scss";

import App from "~/javascript/App.vue";
import router from "~/javascript/router/index.js";

const app = createApp(App);

// global components
import mitt from "mitt";
window.Emitter = mitt();

import Icon from "~/javascript/components/Icon.vue";
app.component("Icon", Icon);
import FlashMessage from "~/javascript/components/FlashMessage.vue";
app.component("FlashMessage", FlashMessage);
import Breadcrumbs from "~/javascript/components/Breadcrumbs.vue";
app.component("Breadcrumbs", Breadcrumbs);

import { tooltip } from "~/javascript/directives/tooltip";
app.directive("tooltip", tooltip);

app.use(createPinia());
app.use(router);

const nonce = document.querySelector("meta[property=csp-nonce]").nonce;
app.use(PrimeVue, {
  // Default theme configuration for primevue components
  theme: {
    preset: Aura,
    options: {
      prefix: "p",
      darkModeSelector: "asdf", // junk value to prevent PrimeVue from adding dark mode classes
    },
  },
  csp: {
    nonce: nonce,
  },
});

router.isReady().then(() => {
  app.mount("#app");
});
